import * as angular from 'angular';

'use strict';

angular.module('SignInWithGoogleModule')
    .service('SignInWithGoogleService', SignInWithGoogleService)
    .config(SignInWithGoogleConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function SignInWithGoogleConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

SignInWithGoogleService.$inject = ['$http', 'Configuration', 'Data', '$window'];

function SignInWithGoogleService($http, Configuration, Data, $window): void {
    let service = this;

    let apiUrl = Configuration.getApiUrl();


    return service;
}
