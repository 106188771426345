import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
    .controller('LookupController', LookupController);

// Injection of each class
LookupController.$inject = ['$scope', 'ScopeAndLocal', 'Auth', 'LanguagesFactory',
    '$window', 'Data', 'LookupService', 'SCS'];

// Controller Constructor
function LookupController($scope, ScopeAndLocal, Auth, LanguagesFactory,
     $window, Data, LookupService, SCS) {

    let vm = this;
    vm.rafa = 'FAIL'

    vm.$onInit = async function () {
        try {
            // console.log('Start: Lookup Controller');
            ScopeAndLocal.startVariable(vm, $scope, 'lc');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'entry');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            vm.auth = Auth;
            vm.isCustomer = vm.auth.isCustomer();
            vm.roleFailed = vm.auth.roleFailed();
            vm.languages = LanguagesFactory;
            vm.cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');
            vm.userRoles = await Auth.getUserRoles();
            vm.fieldToSearch = undefined;
            vm.specificSearch = false;
            await vm.loadModuleCategory();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will be validating the form lookup.
     */
    vm.validateDataToLookup = (event) => {

        // prevent an error from being thrown when we key back to nothing
        if(event.keyCode != null && event.keyCode == 8) {
            return true;
        }

    }

    /**
     * This is the start of the search action of this lookup
     * data.
     *
     * @param event
     */
    vm.searchAction = async function (event) {
        try
        {
            vm.noDataFound = false;
            vm.dataManagerData = undefined;

            // Before ddo anything we need to make sure that we have the basis to search
            vm.validateDataToLookup(event);

            // after press enter, should start the search
            if(event != undefined){
                if(event =='button' || event.keyCode == 13){
                    vm.loadingSearch = true;
                    let filterSlugToFilterBy = vm.fieldToSearch != undefined && vm.fieldToSearch.field_slug != undefined ? vm.fieldToSearch.field_slug : '';
                    let data = await  Data.lookUpData(vm.dataToLookUp, vm.jobToLookUp, filterSlugToFilterBy, vm.specificSearch);
                    vm.loadingSearch = false;

                    vm.dataManagerData = data.data.data;
                    if (vm.dataManagerData.length == 0) {
                        vm.noDataFound = true;
                    }
                    $scope.$apply();
                }
            }

        } catch (exception) {

            switch (exception.constructor.name) {
                case ('EmptyFieldException'):
                    console.log(exception.message);
                    break;

                default:
                    console.log(exception.data.message);
            }

        } finally {
            vm.loadingSearch = false;
        }
    }

    /**
     * This is the action of click of the refined search to be
     * looking for any module.
     */
    vm.anyModuleClick = () => {
        vm.noDataFound=false;
        vm.jobToLookUp=null;
    }

    /**
     * This is the setup started before the click on any module.
     */
    vm.anyModuleInit = () => {
        vm.selectedCategoryToCheck='any';
        vm.jobToLookUp=null;
    }

    /**
     * This will be the init function for the components/lookup/template/form.html
     * specific modules.
     *
     * @param status
     */
    vm.specificModuleInit = async (status) => {
        vm.noDataFound=false;
        vm.sortBy='title';
        vm.jobTypeToFilterBy=status.status;
        await vm.getUsersJobs(vm.sortBy,vm.jobTypeToFilterBy);
    }

    /**
     * This was the previous validation on the
     * dataManager.html
     */
    vm.validate = function () {
        try {

            let valid = false;

            if(vm.isCustomer == 0 && vm.roleFailed == false){
                valid = true;
            }

            return valid;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will return the validate function
     * and return the opposite value.
     */
    vm.invalidate = function () {
        try {

            let invalid = false;

            if(vm.isCustomer == 1 || vm.roleFailed == true){
                invalid = true;
            }

            return invalid;

        } catch (e) {
            console.log(e);
        }

        return ! vm.validate();
    }

    /**
     * This will be loading all jobs for the current CartolyticsCustomerID.
     */
    vm.loadModuleCategory = async function () {
        try
        {
            vm.moduleCategory = await LookupService.getModuleCategory();
        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }

    }

    /**
     * This will load the user jobs.
     *
     * @param sortBy
     * @param jobTypeToFilterBy
     */
    vm.getUsersJobs = async function (sortBy, jobTypeToFilterBy) {
        try {
            vm.sortBy = sortBy;
            if (typeof (vm.resultLimit) == 'undefined') {
                vm.resultLimit = 10;
            }
            if (typeof (vm.currentPage) == 'undefined') {
                vm.currentPage = 1;
            }

            let data = await Data.getUsersJobs(vm.sortBy, vm.master, vm.sortByOrder, jobTypeToFilterBy,
                vm.resultLimit, vm.currentPage, vm.stateName);

            vm.jobList = data.data.data.jobs;
            vm.upperLimit = data.data.data.total;
            if (vm.keepASCD_DESC_Settings == false) {
                if (vm.sortByOrder === 'ASC') {
                    vm.sortByOrder = 'DESC';
                } else {
                    vm.sortByOrder = 'ASC';
                }
            }

            vm.keepASCD_DESC_Settings = false;
            if (typeof (vm.jobTypeToFilterBy) === 'undefined' && typeof vm.jobList[0] != 'undefined') {
                vm.jobTypeToFilterBy = vm.jobList[0]['activity_status'];
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will start on each autocomplete field.
     */
    vm.autocompleteBootstrap = () => {
        // starting with clean search bar
        vm.searchText = '';
        vm.selectedItem = undefined;
    }

    /**
     * This will make the search bu the field
     */
    vm.searchByFieldName = async (fieldNameToSearch) => {

        let fields = [];

        let data = await SCS.searchByFieldName(fieldNameToSearch)
        if(data.fields != undefined){
            fields = data.fields;
        }

        return fields;
    }

    /**
     * This will change the field to search.
     *
     * @param item
     */
    vm.selectedItemChange = async (item) => {
        vm.fieldToSearch = item;
    }


}

export default LookupController;
