import * as angular from 'angular';

"use strict";

angular.module('SignInWithGoogleModule')
    .controller('SignInWithGoogleController', SignInWithGoogleController);

// Injection of each class
SignInWithGoogleController.$inject = ['SignInWithGoogleService', '$scope', 'ScopeAndLocal', 'Configuration'];

// Controller Constructor

function SignInWithGoogleController(SignInWithGoogleService, $scope, ScopeAndLocal, Configuration) {
    let vm = this;

    vm.login_uri = Configuration.getApiUrl() + 'authenticate-with-gsi';

    vm.$onInit = function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            vm.loadAppSettings();

        } catch (error) {
            console.log(error);
        }
    }

    vm.loadAppSettings = async function () {
        try {

            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

            angular.forEach(appSettingParsed.data, function (settingEntry) {
                if (settingEntry.setting_key == 'sign_in_with_google') {
                    vm.sign_in_with_google = Boolean(Number(settingEntry.setting_value));
                }
                if (settingEntry.setting_key == 'client_id') {
                    vm.client_id = settingEntry.setting_value;
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

}

export default SignInWithGoogleController;
