import * as angular from 'angular';

"use strict";

angular.module('UrlModule')
    .service('UrlService', UrlService)
    .config(UrlConfiguration);


/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
 function UrlConfiguration($compileProvider: { aHrefSanitizationWhitelist: (arg0: RegExp) => void; }) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
}

UrlService.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Page Link Service, all methods of service
 * will be available from outside.
 *
 * @param $http
 * @param Configuration
 * @constructor
 */
function UrlService($http, Configuration) {

    let service = this;

    /**
     * This gets the page link information passing id as parameter
     * @param pageLinkId
     * @returns {*}
     */
    service.getPageLinkDataById = async (pageLinkId: any) => {

        const apiBase = Configuration.getApiUrl() + 'get-page-link-by-id';

        const data = {
            'params': {
                pageLinkId
            }
        };

        const response = await $http.get(apiBase, data);
        return response.data.pageLink;
    }

    service.updateLinkUrl = async (id, newLinkName, newLinkUrl, sectionID, orderID, icon, newRoles, newTab, pageId) => {

        const apiBase = Configuration.getApiUrl() + 'update-page-link';

        let data = {
            id,
            name: newLinkName,
            url: newLinkUrl,
            section_id: sectionID,
            order_id: orderID,
            icon,
            newRoles,
            new_tab: newTab,
            page_id: pageId
        };

        return $http.post(apiBase, data);

    }

    /**
     * Fetches the role group IDs
     * @param urlId
     */
    service.getRoleGroupIdsByUrlId = async (urlId) => {
        const apiBase = Configuration.getApiUrl() + 'get-role-group-ids';

        let data = {
            url_id: urlId
        };

        return $http.post(apiBase, data);
    };

    /**
     * Fetches the role names
     * @param roleGroupIds
     */
    service.getRoleNamesByIds = async (roleGroupIds) => {
        const apiBase = Configuration.getApiUrl() + 'get-role-names';

        let data = {
            ids: roleGroupIds
        };

        return $http.post(apiBase, data);
    };


    return service

}
