import { DoBootstrap, NgModule, Inject } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { UpgradeModule, setAngularJSGlobal } from "@angular/upgrade/static";
import "zone.js";
import "zone.js/dist/long-stack-trace-zone.js";
import * as angular from "angular";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { LayoutModule } from "@angular/cdk/layout";
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AppRoutingModule } from "@/angular-blocworx/components/routes/app-routing.module";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";

// components
import { TextFieldInForm } from "@/angular-blocworx/components/fields/text/text-field-in-form.component";
import { TextFieldInData } from "@/angular-blocworx/components/fields/text/text-field-in-data.component";
import { TextFieldCreateEditRadioButton } from "@/angular-blocworx/components/fields/text/text-field-create-edit-radio-button.component";
import { TextFieldCreateEditPopup } from "@/angular-blocworx/components/fields/text/text-field-create-edit-popup.component";
import { ChangeBlocModule } from "@/angular-blocworx/components/BlocSettings/changeBlocModule/change-bloc-module.component";
import { AutocompleteSearch } from "@/angular-blocworx/components/autocompleteSearch/autocomplete-search.component";
import { VersionControl } from "@/angular-blocworx/components/admin/version-control/version-control.component";
import { EvervaultAppSetting } from "@/angular-blocworx/components/admin/app-setting/evervault/evervault-app-setting.component";
import { AppSettingService } from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { ResultsBoxInForm } from "@/angular-blocworx/components/fields/results-box/results-box-in-form.component";
import { ResultsBoxInData } from "@/angular-blocworx/components/fields/results-box/results-box-in-data.component";
import { ResultsBoxCreateEditRadioButton } from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-radio-button.component";
import { ResultsBoxCreateEditPopup } from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-popup.component";
import { TimeoutAppSetting } from "@/angular-blocworx/components/admin/app-setting/timeout/timeout-app-setting.component";
import { LanguagesAppSetting } from "@/angular-blocworx/components/admin/app-setting/languages/languages-app-setting.component";
import { LegacyFeaturesAppSetting } from "@/angular-blocworx/components/admin/app-setting/legacy-features/legacy-features-app-setting.component";
import { LoginAttemptsAppSetting } from "@/angular-blocworx/components/admin/app-setting/login-attempts/login-attempts-app-setting.component";
import { SchedulersAppSetting } from "@/angular-blocworx/components/admin/app-setting/schedulers/schedulers-app-setting.component";
import { SignInGoogleAppSetting } from "@/angular-blocworx/components/admin/app-setting/sign-in-google/sign-in-google-app-setting.component";
import { AppSettings } from "@/angular-blocworx/components/admin/app-setting/app-setting.component";

import { HtmlFieldCreateEditPopup } from "@/angular-blocworx/components/fields/html/html-field-create-edit-popup.component";
import { HtmlFieldCreateEditRadioButton } from "@/angular-blocworx/components/fields/html/html-field-create-edit-radio-button.component";
import { HtmlFieldInData } from "@/angular-blocworx/components/fields/html/html-field-in-data.component";
import { HtmlFieldInForm } from "@/angular-blocworx/components/fields/html/html-field-in-form.component";
import { SeparatorNewFieldCreateEditPopup } from "@/angular-blocworx/components/fields/separator/separator-field-create-edit-popup.component";
import { SeparatorFieldCreateEditRadioButton } from "@/angular-blocworx/components/fields/separator/separator-field-create-edit-radio-button.component";
import { SeparatorFieldInData } from "@/angular-blocworx/components/fields/separator/separator-field-in-data.component";
import { SeparatorFieldInForm } from "@/angular-blocworx/components/fields/separator/separator-field-in-form.component";

import { ManageAndBuildTemplatesComponent } from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/manage-and-build-templates.component";
import { TemplateList } from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list.component";
import { TemplateListEdit } from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list-edit.component";

import { DataEditHistory } from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history.component";
import { FindandReplace } from "@/angular-blocworx/components/manageData/find-and-replace/find-and-replace.component";
import { ReplaceConfirmation } from "@/angular-blocworx/components/manageData/find-and-replace/replace-confirmation.component";
import { ReplacedData } from "@/angular-blocworx/components/manageData/find-and-replace/replaced-data.component";
import { TemplateExporter } from "@/angular-blocworx/components/scanStation/template-exporter/template-exporter.component";
import { DataEditHistoryDialog } from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history-dialog.component";
import { ScanStationComponent } from "@/angular-blocworx/components/scanStation/scan-station.component";
import { DeleteAllData } from "@/angular-blocworx/components/deleteAllData/delete-all-data.component";
import { DeleteAllDataConfirmation } from "@/angular-blocworx/components/deleteAllData/delete-all-data-confirmation.component";
import { LookUpData } from "@/angular-blocworx/components/manageData/look-up-data/look-up-data.component";
import { LookUpDataResults } from "@/angular-blocworx/components/manageData/look-up-data/look-up-data-results.component";
import { ManageData } from "@/angular-blocworx/components/manageData/manage-data.component";

import { EditPageDetails } from "@/angular-blocworx/components/page/edit-page-details/edit-page-details.component";
import { EditPageHeader } from "@/angular-blocworx/components/page/edit-page-header/edit-page-header.component";
import { EditPageSectionsPagesModules } from "@/angular-blocworx/components/page/edit-page-sections-pages-modules/edit-page-sections-pages-modules.component";
import { EditPageFooter } from "@/angular-blocworx/components/page/edit-page-footer/edit-page-footer.component";
import { PageComponent } from "@/angular-blocworx/components/page/page.component";

import { WysiwygFieldInData } from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-in-data.component";
import { WysiwygFieldInForm } from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-in-form.component";
import { WysiwygCreateEditFieldPopup } from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-popup.component";
import { WysiwygCreateEditFieldRadioButton } from "@/angular-blocworx/components/fields/wysiwyg/wysiwyg-radio-button.component";
import { WysiwygForUserFieldInData } from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-in-data.component";
import { WysiwygForUserFieldInForm } from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-in-form.component";
import { WysiwygForUserCreateEditFieldRadioButton } from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-radio-button.component";
import { WysiwygForUserCreateEditFieldPopup } from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user-popup.component";
import { TinyMceContainer } from "@/angular-blocworx/components/fields/wysiwyg/tiny-mce-container.component";

// directives
import { NextOrPreviousFieldDirective } from "@/angular-blocworx/directives/app.nextorpreviousfield";
import { ProceedWithPushToLivePopupComponent } from "@/angular-blocworx/components/admin/version-control/proceed-with-push-to-live-popup.component";

import { CalendarResourcePlanningInForm } from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-form.component";
import { CalendarResourcePlanningInData } from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-data.component";
import { CalendarResourcePlanningCreateEditPopup } from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-create-edit-popup.component";
import { CalendarResourcePlanningEditRadioButton } from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-edit-radio-button.component";
import { AddNewRecordComponent } from "@/angular-blocworx/components/fields/calendar-resource-planning/add-new-record.component";
import { EditRecordComponent } from "@/angular-blocworx/components/fields/calendar-resource-planning/edit-record.component";
import { ShowCountedDataPopup } from "@/angular-blocworx/components/fields/calendar-resource-planning/show-counted-data-popup.component";

setAngularJSGlobal(angular);

@NgModule({
    declarations: [
        // From first file + second file combined
        TextFieldInForm, TextFieldInData, TextFieldCreateEditRadioButton, TextFieldCreateEditPopup,
        CalendarResourcePlanningInForm, CalendarResourcePlanningInData, CalendarResourcePlanningCreateEditPopup, CalendarResourcePlanningEditRadioButton,
        ChangeBlocModule, AutocompleteSearch, NextOrPreviousFieldDirective, VersionControl, ProceedWithPushToLivePopupComponent, AddNewRecordComponent,
        EditRecordComponent, ShowCountedDataPopup, EvervaultAppSetting, ResultsBoxInForm, ResultsBoxInData, ResultsBoxCreateEditRadioButton, ResultsBoxCreateEditPopup,
        TimeoutAppSetting, LanguagesAppSetting, LegacyFeaturesAppSetting, LoginAttemptsAppSetting, SchedulersAppSetting, SignInGoogleAppSetting, AppSettings,
        ScanStationComponent, ManageAndBuildTemplatesComponent, DataEditHistory, ReplacedData,
        EditPageDetails, EditPageHeader, EditPageSectionsPagesModules, EditPageFooter, PageComponent,
        WysiwygFieldInData, WysiwygFieldInForm, WysiwygCreateEditFieldPopup, WysiwygCreateEditFieldRadioButton,
        WysiwygForUserFieldInData, WysiwygForUserFieldInForm, WysiwygForUserCreateEditFieldRadioButton, WysiwygForUserCreateEditFieldPopup,
        FindandReplace, ReplaceConfirmation, TemplateExporter, DataEditHistoryDialog, TemplateList, TemplateListEdit, TinyMceContainer,
        HtmlFieldCreateEditPopup, HtmlFieldCreateEditRadioButton, HtmlFieldInData,
        HtmlFieldInForm, SeparatorNewFieldCreateEditPopup, SeparatorFieldCreateEditRadioButton, SeparatorFieldInData, SeparatorFieldInForm,
        DeleteAllData, DeleteAllDataConfirmation, LookUpData, LookUpDataResults, ManageData
    ],
    imports: [
        BrowserModule, UpgradeModule, CommonModule, FormsModule, HttpClientModule, ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule, BrowserAnimationsModule, MatDialogModule, MatButtonModule,
        MatIconModule, MatProgressSpinnerModule, MatSnackBarModule, RouterModule.forRoot([]),
        MatDatepickerModule, MatNativeDateModule, MatSelectModule, LayoutModule, EditorModule, AppRoutingModule,
        MatToolbarModule, MatGridListModule, MatTableModule, MatCardModule
    ],
    exports: [
        // Combine exports from both files
        TextFieldInForm, TextFieldInData, TextFieldCreateEditRadioButton, TextFieldCreateEditPopup, ChangeBlocModule, AutocompleteSearch, VersionControl, EvervaultAppSetting,
        ResultsBoxInForm, ResultsBoxInData, ResultsBoxCreateEditRadioButton, ResultsBoxCreateEditPopup, TimeoutAppSetting, LanguagesAppSetting, LegacyFeaturesAppSetting, LoginAttemptsAppSetting, SchedulersAppSetting, SignInGoogleAppSetting, AppSettings,
        WysiwygFieldInData, WysiwygFieldInForm, WysiwygCreateEditFieldPopup, WysiwygCreateEditFieldRadioButton, WysiwygForUserFieldInData, WysiwygForUserFieldInForm,
        WysiwygForUserCreateEditFieldRadioButton, WysiwygForUserCreateEditFieldPopup, ReplaceConfirmation, TemplateExporter, ScanStationComponent, ManageAndBuildTemplatesComponent, DataEditHistory, FindandReplace, DataEditHistoryDialog, ReplacedData,
        EditPageDetails, EditPageHeader, EditPageSectionsPagesModules, EditPageFooter, EditorModule, AppRoutingModule, MatSnackBarModule, PageComponent, TemplateList, TemplateListEdit, TinyMceContainer,
        HtmlFieldCreateEditPopup, HtmlFieldCreateEditRadioButton, HtmlFieldInData, HtmlFieldInForm, SeparatorNewFieldCreateEditPopup, SeparatorFieldCreateEditRadioButton, SeparatorFieldInData, SeparatorFieldInForm,
        CalendarResourcePlanningInForm, CalendarResourcePlanningInData, CalendarResourcePlanningCreateEditPopup, CalendarResourcePlanningEditRadioButton,
        NextOrPreviousFieldDirective, ProceedWithPushToLivePopupComponent, AddNewRecordComponent, EditRecordComponent, ShowCountedDataPopup, DeleteAllData, DeleteAllDataConfirmation, LookUpData, LookUpDataResults, ManageData

    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        AppSettingService,
    ],
})
export class AppModule implements DoBootstrap {
    constructor(@Inject(UpgradeModule) private upgrade: UpgradeModule) {
    }

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.documentElement, ["app"]);
    }
}
