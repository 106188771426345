import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('BlocworxModule')
       .controller('ScanStationController', ScanStationController);

ScanStationController.$inject = ['$scope', 'ScopeAndLocal', 'LanguagesFactory'];

// Controller Constructor
function ScanStationController($scope, ScopeAndLocal, LanguagesFactory) {

    // Creating the view model reference
    let vm = this;
    vm.languages = null;
    vm.alertOpen = false;
    vm.classToSet = undefined;
    vm.confirmDeletion = undefined;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try
        {
            vm.languages = LanguagesFactory;
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'result');
            ScopeAndLocal.startVariable(vm, $scope, 'userRoles');
            ScopeAndLocal.startVariable(vm, $scope, 'lc');
            ScopeAndLocal.startVariable(vm, $scope, 'data');

        } catch (e) {
            console.log(e);
        }
    }
    /**
     * This will update data from a ScanStation
     * @param entryID
     * @param newData
     * @param stationID
     */
    vm.updateData = async function (entryID, newData, stationID) {
        try
        {
            await vm.data.updateData(entryID, newData, stationID, vm.hideAlert);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will delete data for scan station data to delete
     * by sending the entry ID.
     *
     * @param entryID
     */
    vm.deleteData = async function (entryID) {
        try
        {
            await vm.data.deleteData(entryID, vm.hideAlert);
            $('#lookup-submit-button').trigger('click');
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }
}

export default ScanStationController;
