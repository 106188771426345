import * as angular from 'angular';

"use strict";

angular.module('SidebarPagesModule')
    .controller('SidebarPagesController', SidebarPagesController);

// Injection of each class
SidebarPagesController.$inject = ['SidebarPagesFactory', 'SidebarPagesService', 'Configuration',
    '$scope', 'Auth', 'Orwell', 'ScopeAndLocal'];

// Controller Constructor
function SidebarPagesController(SidebarPagesFactory, SidebarPagesService, Configuration,
                                $scope, Auth, Orwell, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = async () => {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'nav');

            // loading the sidebar Pages when we load the component.
            await vm.getSidebarPages();


        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will return the value on the observable content.
     */
    vm.getSelected = () => {
        return Orwell.getObservable('selected').content
    }

    /**
     * This method will return if exist or not something in the vm.sidebarPagesList.
     */
    vm.existSidebarPages = () => {
        let exist = false;
        if (vm.sidebarPagesList != undefined && vm.sidebarPagesList.length > 0) {
            exist = true;
        }
        return exist
    }

    /**
     * This will get the sidebar pages to show on the main Navbar Section.
     */
    vm.getSidebarPages = async function () {
        try {
            let isLoggedIn = await Auth.isUserLoggedIn();

            // this will run only if the user is logged in.
            vm.sidebarPagesList = isLoggedIn
                ? await SidebarPagesFactory.getSidebarPages()
                : [];

            vm.hasScanStationLinks = false;

            for (let value of vm.sidebarPagesList) {
                if (value.scan_station_id != null) {
                    vm.hasScanStationLinks = true;
                    break;
                }
            }

        } catch (e) {
            console.log(e);
        }
    }

}

export default SidebarPagesController;

