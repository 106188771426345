import * as angular from 'angular';

"use strict";

angular.module('PageModule')
    .controller('PageController', PageController);

// Injection of each class
PageController.$inject = ['PageFactory', 'PageService', '$state', 'Configuration', '$scope', 'RolesAndPermissionsFactory', 'Data', '$sce'];

// Controller Constructor
function PageController(PageFactory, PageService, $state, Configuration, $scope, RolesAndPermissionsFactory, Data, $sce) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = async function () {
        try {

            // debugger

            let version = Configuration.getVersion();
            let random = Configuration.getRandomValue();
            vm.editSectionName = [];
            vm.addSubPageShow = [];
            vm.addModuleShow = [];
            vm.addUrlLinkShow = [];
            vm.pageSectionsModulesFrontEnd = 'components/page/template/page-sections-modules-front-end.html?v=' + version + 'rand=' + random;
            vm.stateName = $state.current.name;

            if (vm.stateName == 'home') {
                vm.pageId = 0;
            }

            vm.stateName = $state.current.name;
            if (typeof ($state.params) != 'undefined' && typeof ($state.params.pageId) != 'undefined' && vm.stateName
                != 'home') {
                vm.pageId = $state.params.pageId;
            } else if (vm.stateName != 'home') {
                vm.pageId = 'waiting-on-page-slug';
            }

            if (typeof ($state.params) != 'undefined' && typeof ($state.params.pageSlug) != 'undefined') {
                vm.pageSlug = $state.params.pageSlug;
            } else {
                vm.pageSlug = 'waiting-on-page-id';
            }

            if (typeof ($state.params) != 'undefined' && typeof ($state.params.urlId) != 'undefined') {
                vm.urlId = $state.params.urlId
            }

            vm.pageList = [];
            vm.editSectionName = [];

        } catch (e) {
            console.log(e);
        }

    }

    vm.addNewPage = async function () {
        try {
            await PageFactory.addNewPage(vm.newPageData);
            vm.newPageData = {};
            await vm.getPages();
            alert('New Page Added');

        } catch (e) {
            console.log(e);
        }

    }

    vm.updatePage = async function () {
        try {

            if(vm.pageData.parent_id == "No Parent") {
                vm.pageData.parent_id = null
            }

            await PageFactory.updatePage(vm.pageData);
            await vm.getPages();
            alert('Page Details Now Updated');

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will load an array of pages and respective sections counts in it.
     */
    vm.getPages = async function () {
        try
        {
            vm.pageList = [];
            vm.pagePopUpOpen = [];

            let response = await PageFactory.getPages();

            // if contain data we must add a count of page sections
            if(response.data != undefined && response.data.data != undefined) {

                vm.pageList = response.data.data;

                // this ull update the count of page section for each page.
                for (let page in vm.pageList){
                    vm.pageList[page].countPageSections = await PageFactory.getCountPageSections(
                        vm.pageList[page].id,
                        vm.pageList[page].slug
                    );
                }

            }

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }

    }

    /**
     * This will return the quantity of the page sections
     * for a specific page id.
     *
     * @param pageId
     * @param pageSlug
     */
    vm.getCountPageSections = async (pageId, pageSlug) => {

        try {
            let response = await PageFactory.getCountPageSections(pageId, pageSlug);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    vm.getPageData = async function () {
        try {
            let response = await PageFactory.getPageData(vm.stateName, vm.pageId, vm.pageSlug);
            if(typeof response.data.error && response.data.error == 'Access Denied') {
                vm.accessDenied = true;
            }
            vm.sectionsPagesModules = response.data.sectionsPagesModules;
            vm.pageData = response.data.pageData;

            angular.forEach(vm.sectionsPagesModules, function (section) {
                angular.forEach(section.links, function (link) {
                    if (link.link_type == 'module' && vm.stateName == 'edit-page') {
                        link.ui_sref = 'edit-job-module({' + 'id:"' + link.id + '"})';
                    }

                    if (link.link_type == 'module' && (vm.stateName == 'page' || vm.stateName == 'home')) {
                        link.ui_sref = 'job-module({' + 'uniqueModuleSlug:"' + link.slug + '"})';
                    }

                    if (link.link_type == 'page' && vm.stateName == 'edit-page') {
                        link.ui_sref = 'edit-page({' + 'pageId:"' + link.id + '"})';
                    }

                    if (link.link_type == 'page' && (vm.stateName == 'page' || vm.stateName == 'home')) {
                        link.ui_sref = 'page({' + 'pageSlug:"' + link.slug + '"})';
                    }

                    if(link.link_type == 'url' && vm.stateName == 'edit-page') {
                        link.ui_sref = 'edit-url({' + 'urlId:"' + link.id + '"})'
                    }

                });
            });
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will load the page sections.
     * @param pageId
     */
    vm.getPageSections = async function (pageId) {
        try
        {
            vm.pageSections = [];

            // TODO: check here later on
            if(pageId != null){
                let response = await PageFactory.getPageSections(pageId);
                vm.pageSections = response.data.sections;
                $scope.$apply();
            }



        } catch (e) {
            console.log(e);
        }

    }

    vm.addNewPageSection = async function () {
        try {
            await PageFactory.addNewPageSection(vm.pageId, vm.newSectionName)
            await vm.getPageData(vm.pageId);
            $scope.$apply();
        } catch (e) {
            console.log(e);
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }

    vm.deletePageSection = async function (sectionID) {
        try {
            await PageFactory.deletePageSection(sectionID, vm.pageId)
            await vm.getPageData(vm.pageId);
            $scope.$apply();
            alert('Section deleted');

        } catch (e) {
            console.log(e);
            // alert('Error');
        }

    }

    vm.deletePage = async function (pageId) {
        try {

            vm.pageId = pageId;
            await PageFactory.deletePage(vm.pageId);
            alert('Page is now deleted, you will be redirected back to Home screen.')
            $state.go('home')

        } catch (e) {
            console.log(e);
        }

    }

    vm.updatePageSectionName = async function (newSectionName, sectionID) {
        try {
            await PageFactory.updatePageSectionName(newSectionName, sectionID);
            await vm.getPageData(vm.pageId);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.addSubPage = async function (subPageName, sectionID, orderID) {
        try {
            await PageFactory.addSubPage(subPageName, sectionID, vm.pageId, orderID, vm.newLinkIcon, vm.newRoles, vm.newPageOpenAccess);
            await vm.getPageData(vm.pageId);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.addModule = async function (moduleName, newModuleCategory, sectionID, orderID) {
        try {
			if(newModuleCategory != null) {
				await PageFactory.addModule(moduleName, newModuleCategory, sectionID, vm.pageId, orderID, vm.newLinkIcon);
				await vm.getPageData(vm.pageId);
				vm.pagePopUpOpen[sectionID]=false;
				$scope.$apply();
			} else {
				alert('Please select a module category');
			}
        } catch (e) {
            console.log(e);
        }

    }

    vm.addLinkUrl = async function (newLinkName, newLinkUrl, sectionID, orderID, newRoles, newTab) {
        try {
            await PageFactory.addLinkUrl(newLinkName, newLinkUrl, sectionID, vm.pageId, orderID, vm.newLinkIcon, newRoles, newTab);
            await vm.getPageData(vm.pageId);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteLinkUrl = async function (id) {
        try {
            await PageFactory.deleteLinkUrl(id);
            await vm.getPageData(vm.pageId);
            $scope.$apply();

        } catch (e) {
            console.log(e);
            // alert('Error');
        }

    }

    vm.getModulePageAndSection = async function (moduleId) {
        try {
            let data = await PageFactory.getModulePageAndSection(moduleId);
            vm.modulePageId = data.data.data.pageId;
            vm.moduleSectionId = data.data.data.sectionId;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.addExistingModuleToPage = async function (moduleId, sectionId, moduleIcon) {
        try {
            await PageFactory.addExistingModuleToPage(moduleId, sectionId, moduleIcon);
            $scope.$apply();
            alert('Module Location Successfully Updated');

        } catch (e) {
            console.log(e);
        }

    }

    vm.getRolesAndPermissions = async function () {
        try {
            let data = await RolesAndPermissionsFactory.getRolesAndPermissions();
            vm.rolesAndPermissions = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.removeRoleFromNewPage = async function (roleId) {
        let index = vm.newRoles.indexOf(roleId);
        delete vm.newRoles[index];
    }

    vm.removeRoleFromExistingPage = async function (roleId) {
        let index = vm.pageData.roles.indexOf(roleId);
        delete vm.pageData.roles[index];
    }


    vm.reorderSections = async function (direction, sectionId) {
        await PageFactory.reorderSections(direction, sectionId, vm.pageId);
        await vm.getPageData();
        $scope.$apply();

    }

    vm.getJobStatuses = async function () {
        try {
            let data = await Data.getJobStatuses();
            vm.jobStatuses = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    vm.toTrusted = function (htmlCode) {
        try
        {
            let elementToReturn = '';

            // some integers and floats a failing, we will do a try/catch in case anything unusual is fed in here
            if(htmlCode != null){
                elementToReturn = htmlCode.toString();
            }

            return $sce.trustAsHtml(elementToReturn);

        } catch(e) {
            console.log(e)
        }
    }

    /**
     * This will start the section on
     * src/components/page/template/edit-page.html
     *
     * @param sectionId
     */
    vm.startSections =  (sectionId) => {
        vm.editSectionName[sectionId] = false;
        vm.addSubPageShow[sectionId] = false;
        vm.addModuleShow[sectionId] = false;
        vm.addUrlLinkShow[sectionId] = false;
    }

    /**
     * This will be responsible to open the editSectionName
     * and close the other ones.
     * @param sectionId
     */
    vm.openEditSectionName = (sectionId) => {
        vm.editSectionName[sectionId] = !vm.editSectionName[sectionId];
        Object.keys(vm.addSubPageShow).forEach(key => vm.addSubPageShow[key] = false);
        Object.keys(vm.addModuleShow).forEach(key => vm.addModuleShow[key] = false);
        Object.keys(vm.addUrlLinkShow).forEach(key => vm.addUrlLinkShow[key] = false);
    }

    /**
     * This will be responsible to open the addSubPageShow
     * and close the other ones.
     * @param sectionId
     */
    vm.openAddSubPageShow = (sectionId) => {
        vm.addSubPageShow[sectionId] = !vm.addSubPageShow[sectionId];
        Object.keys(vm.editSectionName).forEach(key => vm.editSectionName[key] = false);
        Object.keys(vm.addModuleShow).forEach(key => vm.addModuleShow[key] = false);
        Object.keys(vm.addUrlLinkShow).forEach(key => vm.addUrlLinkShow[key] = false);
    }

    /**
     * This will be responsible to open the addModuleShow
     * and close the other ones.
     * @param sectionId
     */
    vm.openAddModuleShow = (sectionId) => {
        vm.addModuleShow[sectionId] = !vm.addModuleShow[sectionId];
        Object.keys(vm.addSubPageShow).forEach(key => vm.addSubPageShow[key] = false);
        Object.keys(vm.editSectionName).forEach(key => vm.editSectionName[key] = false);
        Object.keys(vm.addUrlLinkShow).forEach(key => vm.addUrlLinkShow[key] = false);
    }

    /**
     * This will be responsible to open the addUrlLinkShow
     * and close the other ones.
     * @param sectionId
     */
    vm.openAddUrlLinkShow = (sectionId) => {
        vm.addUrlLinkShow[sectionId] = !vm.addUrlLinkShow[sectionId];
        Object.keys(vm.addSubPageShow).forEach(key => vm.addSubPageShow[key] = false);
        Object.keys(vm.addModuleShow).forEach(key => vm.addModuleShow[key] = false);
        Object.keys(vm.editSectionName).forEach(key => vm.editSectionName[key] = false);
    }

}
