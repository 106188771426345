import * as angular from 'angular';

"use strict";

angular.module('UrlModule')
    .controller('UrlController', UrlController);

// Injection of each class
UrlController.$inject = ['$state', '$scope', 'UrlService', 'ScopeAndLocal', 'RolesAndPermissionsFactory', 'PageFactory'];

// Controller Constructor
function UrlController($state, $scope, UrlService, ScopeAndLocal, RolesAndPermissionsFactory, PageFactory) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'page');

            if (typeof ($state.params) != 'undefined' && typeof ($state.params.urlId) != 'undefined') {
                vm.urlId = $state.params.urlId
                vm.pageLink = await UrlService.getPageLinkDataById(vm.urlId)
            }

            await vm.getRoles();
            await vm.getPages();
            await vm.loadRolesByUrlId();

            $scope.$watch(() => vm.pageLink.page_section.page_id, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    vm.hasChanged = true;
                    if (vm.pageList[newValue]) {
                        const selectedPage = vm.pageList[newValue];
                        vm.formattedPageTitle = selectedPage.title.toLowerCase().replace(/\s+/g, "-");
                    } else {
                        vm.formattedPageTitle = "home";
                    }
                }
            });

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Gets role names along with their ID's to be displayed
     */
    vm.loadRolesByUrlId = async function () {
        try {
            // Fetch role group IDs that match the URL ID
            let roleGroupIdsResponse = await UrlService.getRoleGroupIdsByUrlId(vm.urlId);
            if (roleGroupIdsResponse && roleGroupIdsResponse.data) {
                let roleGroupIds = roleGroupIdsResponse.data;

                // Fetch role names for the given IDs
                let roleNamesResponse = await UrlService.getRoleNamesByIds(roleGroupIds);
                if (roleNamesResponse && roleNamesResponse.data) {
                    vm.roleNames = roleNamesResponse.data.data;
                    const idsArray = Object.keys(vm.roleNames);
                    vm.newRoles = idsArray;

                } else {
                    vm.roleNames = [];
                }
            }

        } catch (e) {
            console.log("Error loading roles by URL ID:", e);
            vm.roleNames = [];
        } finally {
            $scope.$apply();
        }
    };

    vm.getPages = async () => {

        let response = await PageFactory.getPages();

        // if contain data we must add a count of page sections
        if (response.data != undefined && response.data.data != undefined) {

            vm.pageList = response.data.data;

        }
    }

    vm.getPageSections = async function (pageId) {
        try {
            let response = await PageFactory.getPageSections(pageId);
            vm.pageSections = response.data.sections;

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }

    }

    vm.getPageLinkDataById = async function (pageLinkId) {
        vm.pageLink = await UrlService.getPageLinkDataById(pageLinkId)
    }

    vm.getRoles = async () => {
        try {
            let data = await RolesAndPermissionsFactory.getRolesAndPermissions()
            vm.rolesAndPermissions = data.data.data

            vm.newRoles = []
            vm.pageLink.role_group.forEach(element => {
                vm.newRoles.push(element.id)
            });

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }

    vm.removeRoleFromNewPage = async function (roleId) {

        vm.newRoles = vm.arrayRemove(vm.newRoles, roleId);

        if (vm.roleNames[roleId]) {
            delete vm.roleNames[roleId];
        }

    }

    vm.addToNewRoles = (newRole) => {
        // includes the role only once
        if (!vm.newRoles.includes(newRole)) {
            vm.newRoles.push(newRole)

            // Find the role in rolesAndPermissions and add it to roleNames
            vm.rolesAndPermissions.forEach(role => {
                if (role.id === newRole) {
                    vm.roleNames[role.id] = { id: role.id, name: role.name };
                }
            });
        }
    };

    vm.arrayRemove = (array, value) => {

        return array.filter(function (element) {
            return element != value;
        });
    }

    vm.updateLinkUrl = async function (newLinkName, newLinkUrl, sectionID, orderID, icon, newRoles, newTab, pageId) {
        try {

            if (!sectionID || sectionID === "0") {
                alert('Please select a Page Section before updating the URL link.');
                return;
            }

            await UrlService.updateLinkUrl(vm.urlId, newLinkName, newLinkUrl, sectionID, orderID, icon, newRoles, newTab, pageId);
            await vm.getPageLinkDataById(vm.urlId);
            alert('URL Link Details Updated');

            if (!vm.hasChanged) {
                window.location.href = '/';
            } else {
                if (vm.formattedPageTitle === 'home') {
                    window.location.href = '/';
                } else {
                    $state.go('page', { pageSlug: vm.formattedPageTitle });
                }
            }

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }

    }
}
